import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { ProjectDescription } from "../components"

const ProjectsIndex = props => {
  const { data, location } = props
  const siteTitle = data.site.siteMetadata.title
  const projects = data.allMarkdownRemark.edges
  const pictures = {}

  // Create slug keys for each picture nodes
  data.allFile.edges.forEach(edge => {
    pictures[`projects/${edge.node.relativeDirectory}/`] = edge.node
  })

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Projects" />
      <article id="projects">
        <header>
          <h2>Projects</h2>
        </header>
        <section>
          {projects.map(({ node }) => {
            const { fluid } = pictures[node.fields.slug].childImageSharp
            return (
              <ProjectDescription
                key={node.fields.slug}
                node={node}
                fluid={fluid}
              />
            )
          })}
        </section>
      </article>
    </Layout>
  )
}

export default ProjectsIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "projects" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            site
            github
            video
          }
        }
      }
    }
    allFile(
      filter: {
        childImageSharp: { fluid: { originalName: { eq: "index.jpg" } } }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 640, maxHeight: 360, quality: 100) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
          relativeDirectory
          relativePath
        }
      }
    }
  }
`
